<template>
  <b-overlay :show="loading">
    <!-- <form-filter-export @onExport="exportpengirimanBarang"></form-filter-export> -->
    <b-card title="Daftar Barang yang belum ditempatkan ke Penyimpanan Toko">
      <!-- <b-row v-if="allowCreate()">
        <b-col class="my-1">
          <b-button v-b-modal.modal-tambah variant="primary" v-if="allowCreate() && myGudang"
            @click.prevent="$router.push('/owner/pengiriman/tambah')">
            
            <feather-icon icon="PlusIcon" class="mr-50" />
            Pengiriman Barang ke Cabang
          </b-button>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect"
            class="mb-0">
            <b-input-group size="sm">
              <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
            class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Tekan enter untuk cari"
                @keydown.enter.prevent="getpengirimanBarangs()" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="
                  filter = '';
                getpengirimanBarangs();
                ">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table small striped hover responsive :per-page="perPage" :current-page="currentPage"
            :items="pengirimanBarangs" :fields="fieldsasli" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(no)="row">
              <strong class="text-center">
                {{ row.index + 1 }}
              </strong>
            </template>

            <!-- <template #cell(status_ket)="data">
              <b-badge :variant="ket_selesai[1][data.value]">
                {{ ket_selesai[0][data.value] }}
              </b-badge>
            </template> -->

            <template #cell(selesai)="data">
              <b-badge variant="primary">
                {{ data.item.status_ket ? data.item.status_ket.toUpperCase() : "-" }}
              </b-badge>
            </template>
            <template #cell(po)="{ item }">
              {{ item.po ? item.po.no : "po kosong" }}
            </template>

            <template #cell(tanggal_pengiriman)="{ item }">
              {{ humanDate(item.tanggal) }}
            </template>

            <template #cell(tanggal_invoice)="{ item }">
              {{ humanDate(item.tanggal_invoice) }}
            </template>
            <template #cell(actions)="{ item }">
              <section class="flex item-center">
                <b-button v-b-tooltip.hover.right="'Tempatkan Barang ke Penyimpanan'" size="sm" @click="tempatkan(item)"
                  class="mr-1" variant="outline-info">
                  <feather-icon icon="LogInIcon" />
                  Tempatkan Barang ke Penyimpanan
                </b-button>
                <!-- <b-button v-b-tooltip.hover.right="'Detail'" size="sm"
                  @click="$router.push(`/owner/pengiriman/detail/${item.id}`)" class="mr-1" variant="outline-primary">
                  <feather-icon icon="EyeIcon" />
                </b-button> -->
              </section>
            </template>
            <template #cell(actionsCabang)="{ item }">
              <section class="flex item-center">
                <b-button v-if="item.jumlah <= 0" disabled v-b-tooltip.hover.right="'Sudah di tempatkan'" size="sm"
                  @click="tempatkan(item)" class="mr-1" variant="outline-success">
                  <feather-icon icon="LogInIcon" />
                  Sudah di tempatkan
                </b-button>
                <b-button v-if="item.jumlah > 0" v-b-tooltip.hover.right="'Tempatkan Barang ke Penyimpanan'" size="sm"
                  @click="tempatkan(item)" class="mr-1" variant="info">
                  <feather-icon icon="LogInIcon" />
                  Tempatkan Barang ke Penyimpanan
                </b-button>
                <!-- <b-button v-b-tooltip.hover.right="'Detail'" size="sm"
                  @click="$router.push(`/owner/pengiriman/detail/${item.id}`)" class="mr-1" variant="outline-primary">
                  <feather-icon icon="EyeIcon" />
                </b-button> -->
              </section>
            </template>

            <template #row-details="row">
              <b-card>
                <ul>
                  <li v-for="(value, key) in row.item" :key="key">
                    {{ key }}: {{ value }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
            class="my-0" />
        </b-col>
      </b-row>
    </b-card>
    <b-modal v-if="allowCreate() || allowUpdate()" v-model="showModal" id="modal-" ok-title="Tutup" ok-variant="secondary"
      ok-only centered :title="'Penempatan Barang : ' + namabarang" size="xl">
      <b-row>
        <b-col>
          <b-form-group label="Buat Penyimpanan Baru untuk barang ini?">
            <b-form-radio v-model="mode" name="some-radios" value="2">Buat Penyimpanan baru</b-form-radio>
            <b-form-radio v-model="mode" name="some-radios" value="1">Pilih penyimpanan yang sudah ada</b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <div class="d-flex">
        <feather-icon icon="PackageIcon" size="19" />
        <h6 class="ml-50 mb-2">Penyimpanan Barang : {{ this.namabarang }}</h6>
      </div> -->
      <b-row>
        <!-- Penempatan gudang -->
        <b-col v-if="mode == 2" sm="6" md="6" lg="6" xl="6">
          <b-card title="Buat Penyimpanan Baru" border-variant="primary">
            <validation-observer ref="form2">
              <form>
                <b-row>
                  <!-- <b-col sm="4" md="4" lg="4" xl="4">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="gudang" rules="required">
                    <label for="">Pilih Gudang</label>
                    <b-form-select :options="dataGudang" v-model="form.gudang_id" :required="!form.gudang_id"
                      :reduce="option => option.value" label="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
                  <b-col sm="4" md="4" lg="4" xl="4">
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="blok" rules="required">
                        <label for="">Pilih Blok</label>
                        <b-form-select :options="blok_id" v-model="form.blok_id" :required="!form.blok_id"
                          :reduce="option => option.value" label="text" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" md="4" lg="4" xl="4">
                    <b-form-group>
                      <label for="">Pilih Pallet</label>
                      <b-form-select :options="palet_id" v-model="form.palet_id" :required="!form.palet_id"
                        :reduce="option => option.value" label="text" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" md="4" lg="4" xl="4">
                    <b-form-group>
                      <label for="">Pilih Rak (optional)</label>
                      <b-form-select v-model="form.rak_id" :options="rak_id" :reduce="option => option.value" label="text"
                        class="mb-2" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" md="4" lg="4" xl="4">
                    <b-form-group>
                      <label for="">Pilih Laci (optional)</label>
                      <b-form-select v-model="form.laci_id" :options="laci_id" :reduce="option => option.value"
                        label="text" class="mb-2" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" md="4" lg="4" xl="4">
                    <b-form-group>
                      <b-button @click.prevent="addBarang" variant="primary">Tambah</b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </form>
            </validation-observer>
          </b-card>
        </b-col>
        <!-- Barang yang sudah disimpan -->
        <b-col v-if="mode == 2" cols="6">
          <b-card title="Daftar Penyimpanan Baru yang akan disimmpan" border-variant="primary">
            <b-table :fields="fieldsAddedItems" bordered striped responsive :items="addedItems">
              <template #cell(no)="row">
                {{ (row.index + 1) }}
              </template>
              <template #cell(lokasi)="{ item }">
                <strong>
                  {{ item.lokasi }}
                </strong>
              </template>
              <template #cell(action)="row">
                <b-link class="text-danger" @click.prevent="addedItems.splice(row.index, 1)">
                  <feather-icon size="24" icon="XCircleIcon"></feather-icon>
                </b-link>
              </template>
            </b-table>
            <div class="d-flex justify-content-end">
              <b-button variant="primary" @click.prevent="submitPenyimpanan">
                Simpan
              </b-button>
            </div>
          </b-card>
        </b-col>
        <b-col v-if="mode == 1" sm="12" md="8" lg="8" xl="8">
          <b-card border-variant="primary" v-if="items.length > 0" title="Pilih Penyimpanan Barang">
            <b-row>
              <b-col sm="12" md="12" lg="12">
                <b-table v-if="loaded" striped small hover responsive :items="items" :fields="fieldsPenyimpananBarangs">
                  <!-- :sort-by.sync="sortByBarangs" :sort-desc.sync="sortDescBarangs"
                    :sort-direction="sortDirectionBarangs" :filter-included-fields="filterOnBarangs" :per-page="perPageBarangs" 
                    @filtered="onFilteredBarangs" -->
                  <template #cell(penyimpanan)="data">
                    <!-- <b-form-checkbox @change="selectAll" v-model="selected" /> -->
                    <b-form-checkbox :value="data.item.id" v-model="pilihbarang" />
                  </template>
                  <template #cell(no)="row">
                    <strong class="text-center">
                      {{ row.index + 1 }}
                    </strong>
                  </template>
                  <template #cell(kode)="{ item }">
                    {{ item.barang ? item.barang.kode : item.barang_id }}
                  </template>
                  <template #cell(nama)="{ item }">
                    {{ item.barang ? item.barang.nama : item.barang_id }}
                  </template>
                  <template #cell(blok)="{ item }">
                    {{ item.blok ? item.blok.blok : item.blok_id }}
                  </template>
                  <template #cell(palet)="{ item }">
                    {{ item.palet ? item.palet.palet : item.palet_id }}
                  </template>
                  <template #cell(rak)="{ item }">
                    {{ item.rak ? item.rak.rak : item.rak_id }}
                  </template>
                  <template #cell(laci)="{ item }">
                    {{ item.laci ? item.laci.laci : item.laci_id }}
                  </template>
                  <template #cell(stok)="{ item }">
                    {{ item.stok ? item.stok : item.jumlah }}
                  </template>
                  <template #cell(satuan)="{ item }">
                    {{ item.barang ? item.barang.satuan.satuan : item.barang_id }}
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
          <b-card border-variant="primary" v-if="items.length == 0" class="text-center">
            <div class="text-danger">
              Barang tersebut belum diatur penyimpanannya
            </div>
          </b-card>
        </b-col>
        <b-col v-if="mode == 1" sm="12" md="4" lg="4" xl="4">
          <b-card border-variant="primary" title="Kuantitas">
            <b-form-group class="mb-0">
              <!-- <label>Kuantitas</label> -->
              <b-input-group size="lg">
                <b-form-input id="Kuantitas" v-model="jumlah" type="search" placeholder="" />
                <b-input-group-append>
                  <b-button variant="success" @click="simpan()">
                    Simpan
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>
<script>
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from '@core/components/form-filter-export/FormFilterExport.vue'
import {
  BOverlay,
  BModal,
  BTable,
  VBTooltip,
  BTooltip,
  BAvatar,
  BCard,
  BBadge,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormTextarea,
} from "bootstrap-vue";
// import mixin from './mixin'
export default {
  // mixins: [mixin],
  components: {
    BForm,
    FormFilterExport,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    ValidationObserver,
    ValidationProvider,
    BCard,
    VBTooltip,
    BTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BOverlay,
    BAvatar,
    BFormSelect,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data: () => ({

    dataBarangs: null,
    caribarang: null,
    pageOptionsBarangs: [10, 20, 50],
    sortByBarangs: null,
    sortDescBarangs: false,
    sortDirectionBarangs: "asc",
    filterBarangs: null,
    filterOnBarangs: [],
    perPageBarangs: 10,
    totalRowsBarangs: 1,
    currentPageBarangs: 1,
    loaded: true,
    //Penyimpanan
    blok_id: [],
    palet_id: [],
    rak_id: [],
    laci_id: [],
    //List penyimpanan yang akan di simpan
    pilihbarang: null,
    addedItems: [],
    fieldsAddedItems: [
      { key: 'no', label: 'No' },
      { key: 'barang', label: 'Barang' },
      { key: 'lokasi', label: 'Lokasi' },
      { key: 'action', label: '#' }
    ],
    mode: 1,
    required,
    password,
    email,
    confirmed,
    params: {
      from_gudang_id: null,
    },
    loading: false,
    id: null,
    form: {
      // form tab content 1
      // gudang_id: 1, // sementara
      from_gudang_id: null,
      to_gudang_id: null,
      no_invoice: null,
      jenis: 0,
      tanggal_invoice: null,
      tanggal_pengiriman: null,
      keterangan: null,
    },
    options: [
      { text: 'Otomatis', value: '1' },
      { text: 'Manual', value: '0' },
    ],
    dataGudang: [],
    otherGudangs: [],
    perPage: 5,
    pageOptions: [3, 5, 10],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    pengirimanBarangs: [],
    directive: {
      "b-tooltip": VBTooltip,
    },
    ket_selesai: [
      {
        0: "Belum ditempatkan",
        1: "Sudah",
      },
      {
        0: "light-danger",
        1: "light-success",
      },
    ],
    fieldsGudang: [
      {
        key: "no",
        label: "No",
      },
      { key: "no_invoice", label: "No. Invoice", sortable: true },
      {
        key: "tanggal_pengiriman",
        label: "Tanggal pengiriman",
        sortable: true,
      },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      { key: "to.nama_gudang", label: "Gudang Tujuan", sortable: true },
      { key: "selesai", label: "Status" },
      { key: "actions", label: "Aksi" },
    ],
    fieldsCabang: [
      {
        key: "no",
        label: "No",
      },
      { key: "barang.nama", label: "Nama Barang", sortable: true },
      {
        key: "jumlah",
        label: "Kuantitas",
        sortable: true,
      },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      // { key: "from.nama_gudang", label: "Gudang Asal", sortable: true },
      // { key: "selesai", label: "Status" },
      { key: "actionsCabang", label: "Aksi" },
    ],
    showModal: false,
    fieldsasli: null,
    fields: [
      {
        key: "no",
        label: "No",
      },
      {
        key: "gudang.nama_gudang",
        label: "Gudang",
      },
      { key: "no_invoice", label: "No. Invoice", sortable: true },
      {
        key: "tanggal",
        label: "Tanggal pengiriman",
        sortable: true,
      },
      // { key: "tanggal_invoice", label: "Tanggal Invoice", sortable: true },
      { key: "selesai", label: "Status" },
      { key: "actions", label: "Aksi" },
    ],
    fieldsPenyimpananBarangs: [
      { key: "penyimpanan", label: "Pilih" },
      { key: "no", label: "No", sortable: true },
      { key: "kode", label: "Kode" },
      { key: "nama", label: "Nama Barang" },
      { key: "blok", label: "blok" },
      { key: "palet", label: "palet" },
      { key: "rak", label: "rak" },
      { key: "laci", label: "laci" },
      { key: "stok", label: "stok" },
      { key: "satuan", label: "Satuan" },
    ],
    showModal: false,
    loaded: true,
    items: [],
    pilihbarang: null,
    barang_id: null,
    jumlah: null,
    sementara: null,
    namabarang: null
  }),
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    "form.jenis"(j) {
      if (j == 1) {
        this.form.no_invoice = this.getNewNoInvoice();
      } else {
        this.form.no_invoice = null;
      }
    },
    "form.blok_id"(blok_id) {
      this.getpalet(blok_id)
    },
    "form.palet_id"(palet_id) {
      this.getRak(palet_id)
    },
    "form.rak_id"(rak_id) {
      this.getLaci(rak_id)
    },
  },
  methods: {
    addBarang() {
      this.$refs.form2.validate().then(success => {
        if (success) {
          let data = {}
          Object.assign(data, this.form)
          data.gudang_id = this.myGudang.id
          data.barang = this.namabarang
          data.barang_id = this.barang_id
          data.jumlah = 0
          // get lokasi
          const blok = this.blok_id.find(blok => blok.value == data.blok_id)
          const palet = data.palet_id ? this.palet_id.find(palet => palet.value == data.palet_id) : null
          const rak = data.rak_id ? this.rak_id.find(rak => rak.value == data.rak_id) : null
          const laci = data.laci_id ? this.laci_id.find(laci => laci.value == data.laci_id) : null

          let lokasi = blok ? `Blok: ${blok.text}` : ''
          if (palet) {
            lokasi += `, Palet: ${palet.text}`
          }
          if (rak) {
            lokasi += `, Rak: ${rak.text}`
          }

          if (laci) {
            lokasi += `, Laci: ${laci.text}`
          }
          data.lokasi = lokasi
          this.addedItems.push(data)
          this.form.blok_id = null,
            this.form.palet_id = null,
            this.form.rak_id = null,
            this.form.laci_id = null
        }
      })
    },
    coba() {
      if (this.isAdminGudang) {
        return this.fieldsasli = this.fieldsGudang
      } else if (this.isCabang) {
        return this.fieldsasli = this.fieldsCabang
      } else {
        this.fieldsasli = this.fields
      }
    },
    batal(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengiriman Barang ke Cabang ini akan dibatalkan`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/batal", {
              pengiriman_id: item.id,
              metode: 'transaksi',
            })
            .then(() => {
              // this.loading = false;
              this.displaySuccess({
                text: "Data berhasil dibatalkan",
              });
              this.getpengirimanBarangs();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async exportpengirimanBarang({ tgl_awal, tgl_akhir, type_export }) {
      if (!tgl_awal || !tgl_akhir) {
        this.displayWarning({
          text: 'Harap isi tanggal awal dan akhir!'
        })
        return false
      }

      const params = {
        tgl_awal,
        tgl_akhir,
        type_export
      }

      try {
        this.loading = true
        const response = await this.doExport(params)
        this.loading = false
        await window.open(response)
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split('/')
          const filename = arrFile[arrFile.length - 1]

          await this.clearExportedFile(filename)
        }, 1000)
      }
      catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    submitPenyimpanan() {
      this.addedItems.map(item => {
        delete item.barang
        delete item.lokasi
      })
      let payload = []
      Object.assign(payload, this.addedItems)

      this.loading = true
      this.$store.dispatch('tokopenyimpanan/save', payload)
        .then(() => {
          this.loading = false
          this.displaySuccess({
            text: 'Penyimpanan Barang berhasil ditambahkan, silahkan pilih penyimpanan yang sudah ada'
          })
this.getStockBarang()
        })
        .catch(e => {
          this.loading = false
          this.displayError(e)
          return false
        })
    },
    submit() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          if (this.id) {
            this.form.id = this.id;
          }
          if (!this.form.keterangan) {
            this.form.keterangan = "-";
          }
          // const payload = this.form;
          const payload = {
            from_gudang_id: this.form.from_gudang_id,
            to_gudang_id: this.form.to_gudang_id,
            karyawan_id: this.user.id,
            items: [
              penyimpanan
            ],
            // keterangan: null,
          }
          try {
            this.label = "Loading...";
            const ajuan = await this.$store.dispatch("pengiriman/save", payload);
            this.label = "Submit";
            if (this.id) {
              this.getData();
              this.displaySuccess({
                text: "Ajuan berhasil disimpan",
              });
            } else {
              this.displaySuccess({
                text: "Ajuan berhasil disimpan",
              });
              this.$router.push(`/pengiriman/add/${ajuan.id}`);
            }
          } catch (e) {
            console.error(e);
            this.displayError(e);
            return false;
          }
        }
      });
    },
    getDataGudang() {
      this.$store
        .dispatch("gudang/getData", {
          jenis: 1,
        })
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          const gudangs = [];
          if (this.isAdminGudang) {
            ter.map((gudang) => {
              const itsMyGudang = gudang.id == this.myGudang.id;
              if (itsMyGudang) {
                this.form.to_gudang_id = this.myGudang.id;
                gudangs.push({
                  value: gudang.id,
                  text: gudang.nama_gudang,
                });
              }
            });
          } else {
            ter.map((item) => {
              gudangs.push({
                value: item.id,
                text: item.nama_gudang,
              });
            });
          }
          this.dataGudang = gudangs;
          ter.map((gudang) => {
            if (gudang.id != this.myGudang.id) {
              this.otherGudangs.push({
                value: gudang.id,
                text: gudang.nama_gudang,
              });
            }
          });
        });
    },
    async getNewNoInvoice() {
      const noInvoice = await this.$store.dispatch(
        "pengiriman/getNewNoInvoice"
      );
      this.form.no_invoice = noInvoice.message.format;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      this.form.tanggal_pengiriman = this.getCurrentDate()
      this.form.tanggal_invoice = this.getCurrentDate()
    },
    resetForm() {
      this.id = null;
      this.form.id = null;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Pengiriman Barang ke Cabang ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        // const allowed = result.value;
        if (result.value) {
          // this.loading = true;
          this.$store
            .dispatch("pengiriman/deletepengiriman", {
              pengiriman_id: item.id,
              metode: "main"
            })
            .then(() => {
              // this.loading = false;
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
              this.getpengirimanBarangs();
            })
            .catch((e) => {
              // this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getpengirimanBarangs() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        filter_by: "nama_lengkap",
        // kode: this.caribarang ? this.caribarang : "",
        to_gudang_id: this.myGudang.id,
      };
      // let payload = this.isAdminGudang
      //   ? { from_gudang_id: this.myGudang.id, order: "desc" }
      //   : { order: "desc" };
      const pengirimanBarangs = await this.$store.dispatch(
        "tokopenyimpanansementara/getData",
        payload
      );
      this.pengirimanBarangs = pengirimanBarangs;
      this.totalRows = this.pengirimanBarangs.length;
    },

    async getDataBarangs(data) {
      // if (this.items > 1)
      this.loading = true;
      this.loaded = false;
      const perPageBrg = parseInt(this.perPageBarangs);
      const currentPageBrg = (parseInt(this.currentPageBarangs) - 1) * perPageBrg;
      const payload = {
        search: this.caribarang != null ? this.caribarang : null,
        order: "desc",
        start: currentPageBrg,
        length: this.perPageBarangs,
        // filter_by: "nama_lengkap",
        // kode: this.caribarang ? this.caribarang : "",
      };
      this.$store
        .dispatch("barang/getDataByIdV2", params)
        .then((response) => {
          // this.items = null
          // console.log('HEMMM',this.items)

          let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          let items_total = this.$store.state.barang.totals;
          this.dataBarangs = items;
          const barangsId = response.map(barang => barang.id)
          // get stock
          this.getStockBarang(barangsId)
          this.totalRowsBarangs = items_total;
          // this.$refs.table.refresh()
          // console.log('HEMMM',this.items)
          // this.$root.$emit("bv::refresh::table", "table");
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    getStockBarang() {
      let params = {
        // barang_id: barangsId.barang_id,
        barang_id: this.barang_id,
        gudang_id: this.myGudang.id
      }

      this.$store.dispatch('tokopenyimpanan/getData', params)
        .then(stocks => {
          // this.items.map(barang => {
          //   barang.stocks = []
          //   const findStocks = stocks.filter(stock => stock.barang && stock.barang.id == barang.id)

          //   if (findStocks && findStocks.length > 0) {
          //     findStocks.forEach(stock => {
          //       barang.stocks.push({
          //         id: stock.id,
          //         stok: stock.stok,
          //         gudang: stock.gudang,
          //         blok: stock.blok,
          //         palet: stock.palet,
          //         rak: stock.rak,
          //         laci: stock.laci,
          //         stok: stock.stok
          //       })
          //     })
          //   }
          // })

          // this.items = this.dataBarangs ? this.dataBarangs.stocks : []
          this.items = stocks
          // console.log('aji', this.items)
        })
        .catch(e => {
          this.displayError(e)
          return false
        })
    },
    tempatkan(item) {
      // const data = item.barang_id
      this.barang_id = item.barang.id
      this.namabarang = item.barang.nama
      this.jumlah = item.jumlah
      this.sementara = item.id
      // console.log('aji', item)                     
      this.getStockBarang()
      this.showModal = true
      this.getDatablok()
    },
    simpan() {
      if (this.pilihbarang == null) {
        this.displayInfo({
          title: 'Harap pilih penyimpanan'
        })
        return
      }
      let payload = {
        penyimpanan_id: this.pilihbarang,
        sementara_id: this.sementara,
        jumlah: this.jumlah
      }
      this.loading = true
      this.$store.dispatch('tokopenempatan/save', [payload])
        .then(() => {
          this.loading = false
          this.displaySuccess({
            text: 'Penyimpanan Barang berhasil ditambahkan!'
          })
          this.sementara = null
          this.jumlah = null
          this.barang_id = null
          this.namabarang = null
          this.getpengirimanBarangs();

          // setTimeout(() => {
          //     this.$router.push('/cabang/penyimpanan-sementara')
          // }, 1000)
        })
        .catch(e => {
          this.loading = false
          this.displayError(e)
          return false
        })
    },
    getDatablok() {
      let params = {
        jenis: 1
      }
      if (this.isAdminGudang) {
        params.gudang_id = this.myGudang.id
      }
      this.$store.dispatch('tokoblok/getData', params)
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.tokoblok.datas))
          ter.map(item => {
            item.value = item.id
            item.text = item.blok
          })
          this.blok_id = ter
        })
    },
    getpalet(blok_id) {
      this.palet_id = []
      this.$store.dispatch('tokopalet/getData', { blok_id })
        .then(palets => {
          palets.forEach(palet => {
            this.palet_id.push({
              value: palet.id,
              text: palet.palet
            })
          })
        })
    },
    getRak(palet_id) {
      this.rak_id = []
      this.$store.dispatch('tokorak/getData', { palet_id })
        .then(raks => {
          raks.forEach(rak => {
            this.rak_id.push({
              value: rak.id,
              text: rak.rak
            })
          })
        })
    },
    getLaci(rak_id) {
      this.laci_id = []
      this.$store.dispatch('tokolaci/getData', { rak_id })
        .then(lacis => {
          lacis.forEach(laci => {
            this.laci_id.push({
              value: laci.id,
              text: laci.laci
            })
          })
        })
    },
  },
  async created() {
    this.loading = true
    await this.getpengirimanBarangs();
    // await this.getDataGudang();
    await this.coba()
    this.loading = false
  },
};
</script>
